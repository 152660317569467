var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { centered: "", "hide-footer": "" },
      on: {
        close: function ($event) {
          return _vm.resetTimeSesion()
        },
        hidden: function ($event) {
          return _vm.onHiddeModal()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [_vm._v(" Expiración de la sesión ")]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.modal.isOpen,
        callback: function ($$v) {
          _vm.$set(_vm.modal, "isOpen", $$v)
        },
        expression: "modal.isOpen",
      },
    },
    [
      _c("p", { staticClass: "text-center" }, [
        _vm._v(
          " Su sesión expirará en " +
            _vm._s(_vm.counter) +
            " segundos por inactividad. Por favor, confirme de estar activo para reestablecer su sesión. "
        ),
      ]),
      _c(
        "b-col",
        { staticClass: "d-flex justify-content-center", attrs: { cols: "12" } },
        [
          _c(
            "b-button",
            {
              attrs: { variant: "outline-danger" },
              on: { click: _vm.resetTimeSesion },
            },
            [
              _c("i", { staticClass: "lar la-check-circle-right" }),
              _vm._v(" Confirmar "),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }