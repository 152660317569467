import axios from "axios";
import store from "@/app/core/store";
import http from "@/app/core/config/axios.config";
import SeguridadService from "@/app/shared/services/seguridad.service";
import EnvironmentService from "@/app/shared/services/environment.service";

export default class AuditoriaService {
  /* AUTENTICACION ------------------------------------------------------------------------------------------------------------ */

  static getAccionesAuditoria() {
    return http.get("/auditoria/ObtenerAcciones").then((response) => {
      const ACCIONES = {};
      response.data.map((accion) => (ACCIONES[accion.descripcion.replace(/ /g, "_")] = accion.descripcion));
      store.dispatch("updateAuditoria", { acciones: ACCIONES, listaAcciones: response.data });
    });
  }

  static saveAuditoria(response, RA) {
    let TA = "",
      VA = "",
      DR = "",
      US = "",
      ID = "",
      VTA = "";

    const { token, publicToken, ip, identificacion, listaAccionesAuditoria, accionesAuditoria } = store.getters;
    const { config, status, data } = response;
    const partesUrl = config.url.split("?")[0].split("/");

    VTA = config.url;
    US = `${identificacion} - ${ip}`;
    ID = accionesAuditoria.AUDITORIA_GENERAL;
    VA = `AUDITORIA - ${partesUrl[1].toUpperCase()}`;
    DR = `PROCESO ${RA ? "CORRECTO" : "INCORRECTO"} - STATUS: ${status}`;
    TA = partesUrl[partesUrl.length - 1].replace(/([a-z])([A-Z])/g, "$1 $2");

    if (config.url.startsWith("/reportes/Extracto") || config.url.startsWith("/reportes/Certificado")) {
      if (config.url.startsWith("/reportes/Extracto")) {
        const mesText = store.getters.listaMeses.find((item) => item.value === config.mes).text;
        ID = accionesAuditoria.CONSULTA_EXTRACTOS;
        VA = `VISUALIZACION EXTRACTO AÑO: ${config.anio} - MES: ${mesText}`;
      } else {
        ID = accionesAuditoria.CONSULTA_CERTIFICADOS;
        VA = `VISUALIZACION CERTIFICADO AÑO: ${config.anio} TIPO: ${store.getters.tiposNotificacion[config.tipo]}`;
      }

      TA = "CREDITO";
      RA = data.estado;
      US = `${identificacion}-${ip}`;
      VTA = config.credito.toString();
      DR = `CONSULTA ${data.estado ? "SATISFACTORIA." : `NO SATISFACTORIA. ${data.mensaje}`}`;
    }

    let body = {
      usuario: US,
      resultadoAccion: RA,
      valorTipoAccion: VTA,
      tipoAccion: TA.toUpperCase(),
      valorAccion: VA.toUpperCase(),
      fechaHora: new Date().toISOString(),
      descripcionResultado: DR.toUpperCase(),
      identificadorAccion: listaAccionesAuditoria.find((item) => item.descripcion === ID).id,
    };

    const configAxios = { headers: {} };

    if (token) configAxios.headers.Authorization = `Bearer ${token}`;
    else configAxios.headers["SV-Public-Key"] = publicToken;

    if (EnvironmentService.isProd || EnvironmentService.isQa) {
      configAxios.headers["SV-Encrypt"] = true;
      configAxios.headers["SV-Encrypt-IV"] = EnvironmentService.SV_IV;
      body = SeguridadService.encriptarDatos(body);
    }

    return axios.post(`${EnvironmentService.baseUrl}/auditoria`, body, configAxios);
  }
}
