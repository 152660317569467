const PagarTarjeta = () => import(/* webpackChunkName: "PagarTarjeta" */ "../views/PagarTarjeta.vue");
const TarjetaRecaudo = () => import(/* webpackChunkName: "TarjetaRecaudo" */ "../views/TarjetaRecaudo.vue");
const ValidarPagoWeb = () => import(/* webpackChunkName: "ValidarPagoWeb" */ "../views/ValidarPagoWeb.vue");
const PagarTarjetaWeb = () => import(/* webpackChunkName: "PagarTarjetaWeb" */ "../views/PagarTarjetaWeb.vue");

export const routesPagos = [
  {
    path: "/tarjeta-recaudo",
    name: "TarjetaRecaudo",
    component: TarjetaRecaudo,
  },
  {
    path: "/pagar-cuota",
    name: "PagarTarjeta",
    component: PagarTarjeta,
  },
];

export const routesPagosWeb = [
  {
    path: "/validar-pago-web",
    name: "ValidarPagoWeb",
    component: ValidarPagoWeb,
    meta: { authGuard: false },
  },
  {
    path: "/pagar-cuota-web",
    name: "PagarTarjetaWeb",
    component: PagarTarjetaWeb,
    meta: { authGuard: false },
  },
];
