export default class EnvironmentService {
  static get isProd() {
    return process.env.NODE_ENV === "prod";
  }

  static get isQa() {
    return process.env.NODE_ENV === "qa";
  }

  static get baseUrl() {
    return process.env.VUE_APP_BASE_API;
  }

  static get timeOut() {
    return process.env.VUE_APP_TIME_OUT;
  }

  static get SV_IV() {
    return process.env.VUE_APP_SV_IV;
  }

  static get SV_KEY() {
    return process.env.VUE_APP_SV_KEY;
  }
}
