import router from "@/app/router";

const state = {
  user: {},
};

const getters = {
  clave: (stateApp) => stateApp.user.clave,
  menuOptions: (stateApp) => stateApp.user.menu,
  idAcceso: (stateApp) => stateApp.user.idAcceso,
  direccion: (stateApp) => stateApp.user.direccion,
  token: (stateApp) => stateApp.user.security?.token,
  isLogin: (stateApp) => stateApp.user.guid != undefined,
  requestGrupo: (stateApp) => stateApp.user.requestGrupo,
  ultimoIngreso: (stateApp) => stateApp.user.ultimoIngreso,
  nombreCliente: (stateApp) => stateApp.user.nombreCliente,
  numeroCelular: (stateApp) => stateApp.user.numeroCelular,
  tipoDocumento: (stateApp) => stateApp.user.tipoDocumento,
  idUltimoAcceso: (stateApp) => stateApp.user.idUltimoAcceso,
  identificacion: (stateApp) => stateApp.user.identificacion,
  correoElectronico: (stateApp) => stateApp.user.correoElectronico,
  refreshToken: (stateApp) => stateApp.user.security?.refreshToken,
  msgActualizacionDatos: (stateApp) => stateApp.user.msgActualizacionDatos,
};

const mutations = {
  setUserData: (stateApp, userData) => {
    stateApp.user = { ...stateApp.user, ...userData };
  },
  setUserClave: (stateApp, userClave) => {
    stateApp.user.clave = userClave;
  },
  cleanUserData: (stateApp) => {
    stateApp.user = {};
  },
  refreshToken: (stateApp, { newToken, newRefreshToken }) => {
    stateApp.user.security.token = newToken;
    stateApp.user.security.refreshToken = newRefreshToken;
  },
};

const actions = {
  logout: ({ commit }) => {
    commit("cleanUserData");
    commit("cleanDataShared");
    commit("setinfoCreditos");
    router.push("/login");
  },
};

export default { state, getters, mutations, actions };
