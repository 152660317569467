import Vue from "vue";
import Vuex from "vuex";
import SecureLS from "secure-ls";
import createPersistedState from "vuex-persistedstate";
import EnvironmentService from "@/app/shared/services/environment.service";

// MODULOS
import auth from "./modules/auth";
import creditos from "./modules/creditos";
import parametros from "./modules/parametros";
import dataShared from "./modules/data-shared";

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { auth, parametros, creditos, dataShared },
  plugins: [
    createPersistedState({
      key: "ls",
      paths: ["auth", "parametros", "creditos", "dataShared"],
      storage: {
        getItem: (key) => {
          if (EnvironmentService.isProd || EnvironmentService.isQa) return ls.get(key);
          return localStorage.getItem(key);
        },
        setItem: (key, value) => {
          if (EnvironmentService.isProd || EnvironmentService.isQa) return ls.set(key, value);
          return localStorage.setItem(key, value);
        },
        removeItem: (key) => {
          if (EnvironmentService.isProd || EnvironmentService.isQa) return ls.remove(key);
          return localStorage.removeItem(key);
        },
      },
    }),
  ],
});
