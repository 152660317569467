import Vue from "vue";
import App from "./app/App.vue";
import router from "@/app/router";
import store from "@/app/core/store";

// PLUGINS
import "@/app/core/plugins";

// CSS
import "../node_modules/line-awesome/dist/line-awesome/css/line-awesome.min.css";
import "./styles/theme-bootstrap.scss";
import "./styles/styles.scss";

// INTERCEPTORES
import "@/app/core/interceptors/http";

Vue.config.productionTip = false;

export const aplication = new Vue({ store, router, render: (h) => h(App) }).$mount("#app");
