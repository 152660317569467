import http from "@/app/core/config/axios.config";

export default class ComunesService {
  /* AUTENTICACION ------------------------------------------------------------------------------------------------------------ */

  static logout(entry) {
    return http.post("/comunes/Autenticacion/Logout", entry);
  }

  static refreshToken(entry) {
    return http.post("/comunes/auth/refresh-token", entry);
  }

  /* INFORMACION DEMOGRAFICA -------------------------------------------------------------------------------------------------- */

  static getDepartamentos() {
    return http.get("/comunes/InformacionDemografica/ConsultarDepartamentos/169");
  }

  static getCiudadesByDpto(entry) {
    return http.get(`/comunes/InformacionDemografica/ConsultarMunicipios/${entry}`);
  }

  static getCorregimientosByCiudad(entry) {
    return http.get(`/comunes/InformacionDemografica/ConsultarCorregimientos/${entry}`);
  }

  static getTiposDireccion() {
    return http.get("/comunes/InformacionDemografica/ConsultarListadosDireccion");
  }

  /* GENERACION CODIGOS ------------------------------------------------------------------------------------------------------- */

  static generarCodigoCambiarClave(entry) {
    return http.post("/public/CambiarClave/GenerarCodigo", entry);
  }

  static validarCodigoCambiarClave(entry) {
    return http.post("/public/CambiarClave/ValidarCodigo", entry);
  }

  static reenviarCodigoCambiarClave(entry) {
    return http.post("/public/CambiarClave/GenerarReenvioCodigo", entry);
  }

  static generarCodigoLevantamientoPrenda(entry) {
    return http.post("/public/LevantamientoPrendaCodigoOTP/GenerarCodigo", entry);
  }

  static validarCodigoLevantamientoPrenda(entry) {
    return http.post("/public/LevantamientoPrendaCodigoOTP/ValidarCodigo", entry);
  }

  static reenviarCodigoLevantamientoPrenda(entry) {
    return http.post("/public/LevantamientoPrendaCodigoOTP/GenerarReenvioCodigo", entry);
  }

  static generarCodigoRecuperarClave(entry) {
    return http.post("/public/RecuperarClave/GenerarCodigo", entry);
  }

  static validarCodigoRecuperarClave(entry) {
    return http.post("/public/RecuperarClave/ValidarCodigo", entry);
  }

  static reenviarCodigoRecuperarClave(entry) {
    return http.post("/public/RecuperarClave/GenerarReenvioCodigo", entry);
  }
}
