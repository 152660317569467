const state = {
  dataShared: {},
  modalConfirmaciones: { isOpen: false, titulo: null, tipo: null, mensaje: null },
};

const getters = {
  dataShared: (stateApp) => stateApp.dataShared,
  modalConfirmaciones: (stateApp) => stateApp.modalConfirmaciones,
};

const mutations = {
  setDataShared: (stateApp, data) => {
    stateApp.dataShared = data;
  },
  updateDataShared: (stateApp, data) => {
    stateApp.dataShared = { ...stateApp.dataShared, ...data };
  },
  cleanDataShared: (stateApp) => {
    stateApp.dataShared = {};
  },
  openModalConfirms: (stateApp, data) => {
    stateApp.modalConfirmaciones = { isOpen: true, ...data };
  },
  closeModalConfirms: (stateApp) => {
    stateApp.modalConfirmaciones = { isOpen: false, titulo: null, tipo: null, mensaje: null };
  },
};

const actions = {};

export default { state, getters, mutations, actions };
