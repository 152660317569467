<template>
  <b-modal centered hide-footer v-model="modal.isOpen" @close="resetTimeSesion()" @hidden="onHiddeModal()">
    <template #modal-title>
      Expiración de la sesión
    </template>
    <p class="text-center">
      Su sesión expirará en {{ counter }} segundos por inactividad. Por favor, confirme de estar activo para
      reestablecer su sesión.
    </p>
    <b-col cols="12" class="d-flex justify-content-center">
      <b-button variant="outline-danger" @click="resetTimeSesion">
        <i class="lar la-check-circle-right" /> Confirmar
      </b-button>
    </b-col>
  </b-modal>
</template>

<script>
export default {
  props: {
    modal: {
      type: Object,
      default: () => {
        return { isOpen: false };
      },
    },
  },
  data() {
    return { timer: null, counter: 50 };
  },
  watch: {
    "modal.isOpen"() {
      if (this.modal.isOpen) this.timer = setInterval(() => this.countDownSesion(), 1000);
    },
  },
  methods: {
    countDownSesion() {
      if (this.counter > 0) this.counter -= 1;
      else {
        this.resetTimeSesion();
        this.$router.push("/logout");
      }
    },
    resetTimeSesion() {
      clearInterval(this.timer);
      this.onCloseModal();
    },
    onCloseModal() {
      this.$emit("useModal");
    },
    onHiddeModal() {
      this.counter = 50;
    },
  },
};
</script>
