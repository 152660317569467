const state = {
  ip: null,
  version: null,
  loading: false,
  envioSmsTime: 1,
  minLengthPass: 9,
  maxLengthPass: 15,
  inactivityTime: 1,
  publicToken: null,
  vigenciaHashTime: 1,
  specialCharacters: 0,
  mensajeInicioBanner: null,
  listaVencimientoClave: [],
  listaTiposIdentificacion: [],
  auditoria: { accionesAuditoria: {}, listaAccionesAuditoria: {} },
  infoContactoProgreser: { numeroUnicoColombia: null, whatsappCartera: null },
  infoContacto: { mensajeWsp: null, prefijoContactoWsp: null, numeroContactoWsp: null },
  tiposNotificacion: {
    PAZYSALVO: "PAZ_Y_SALVO",
    RENTA: "DECLARACION_RENTA",
    CERTIDIA: "CREDITO_AL_DIA",
    ESTADOCR: "CARTERA_VIGENTE",
    HISTORIAL: "HISTORIAL_PAGOS",
  },
  listaMeses: [
    { text: "ENERO", value: 1 },
    { text: "FEBRERO", value: 2 },
    { text: "MARZO", value: 3 },
    { text: "ABRIL", value: 4 },
    { text: "MAYO", value: 5 },
    { text: "JUNIO", value: 6 },
    { text: "JULIO", value: 7 },
    { text: "AGOSTO", value: 8 },
    { text: "SEPTIEMBRE", value: 9 },
    { text: "OCTUBRE", value: 10 },
    { text: "NOVIEMBRE", value: 11 },
    { text: "DICIEMBRE", value: 12 },
  ],
};

const getters = {
  ip: (stateApp) => stateApp.ip,
  version: (stateApp) => stateApp.version,
  loading: (stateApp) => stateApp.loading,
  listaMeses: (stateApp) => stateApp.listaMeses,
  publicToken: (stateApp) => stateApp.publicToken,
  envioSmsTime: (stateApp) => stateApp.envioSmsTime,
  infoContacto: (stateApp) => stateApp.infoContacto,
  minLengthPass: (stateApp) => stateApp.minLengthPass,
  maxLengthPass: (stateApp) => stateApp.maxLengthPass,
  vigenciaHashTime: (stateApp) => stateApp.vigenciaHashTime,
  specialCharacters: (stateApp) => stateApp.specialCharacters,
  tiposNotificacion: (stateApp) => stateApp.tiposNotificacion,
  mensajeInicioBanner: (stateApp) => stateApp.mensajeInicioBanner,
  inactivityTime: (stateApp) => stateApp.inactivityTime * 60000000,
  infoContactoProgreser: (stateApp) => stateApp.infoContactoProgreser,
  listaVencimientoClave: (stateApp) => stateApp.listaVencimientoClave,
  accionesAuditoria: (stateApp) => stateApp.auditoria.accionesAuditoria,
  listaTiposIdentificacion: (stateApp) => stateApp.listaTiposIdentificacion,
  listaAccionesAuditoria: (stateApp) => stateApp.auditoria.listaAccionesAuditoria,
};

const mutations = {
  setIp: (stateApp, data) => {
    stateApp.ip = data;
  },
  switchLoading: (stateApp, data) => {
    stateApp.loading = data;
  },
  setVersion: (stateApp, version) => {
    stateApp.version = version;
  },
  setPublicToken: (stateApp, data) => {
    stateApp.publicToken = data;
  },
  setEnvioSmsTime: (stateApp, data) => {
    stateApp.envioSmsTime = data;
  },
  setMinLengthPass: (stateApp, data) => {
    stateApp.minLengthPass = data;
  },
  setMaxLengthPass: (stateApp, data) => {
    stateApp.maxLengthPass = data;
  },
  setInactivityTime: (stateApp, data) => {
    stateApp.inactivityTime = data;
  },
  setVigenciaHashTime: (stateApp, data) => {
    stateApp.vigenciaHashTime = data;
  },
  setSpecialCharacters: (stateApp, data) => {
    stateApp.specialCharacters = data;
  },
  setAccionesAuditoria: (stateApp, data) => {
    stateApp.auditoria.accionesAuditoria = data;
  },
  setListAccionesAuditoria: (stateApp, data) => {
    stateApp.auditoria.listaAccionesAuditoria = data;
  },
  setListaVencimientoClave: (stateApp, data) => {
    stateApp.listaVencimientoClave = data;
  },
  setListaTiposIdentificacion: (stateApp, data) => {
    stateApp.listaTiposIdentificacion = data;
  },
  setInfoContacto: (stateApp, data) => {
    stateApp.infoContacto = data;
  },
  setMensajeInicioBanner: (stateApp, data) => {
    stateApp.mensajeInicioBanner = data;
  },
  setInfoContactoProgreser: (stateApp, data) => {
    stateApp.infoContactoProgreser = data;
  },
};

const actions = {
  updateAuditoria: ({ commit }, data) => {
    commit("setAccionesAuditoria", data.acciones);
    commit("setListAccionesAuditoria", data.listaAcciones);
  },
  updateParams: ({ commit, state: stateApp }, params) => {
    if (stateApp.version !== params.VERSION_FRONT) {
      commit("setVersion", params.VERSION_FRONT);
      return window.location.reload(true);
    }
    const listAux = params.LISTA_VENCIMIENTO_CLAVE.split(",").map((item) => ({
      text: `${item} ${item == 1 ? "mes" : "meses"}`,
      value: parseInt(item),
    }));

    commit("setListaVencimientoClave", listAux);
    commit("setEnvioSmsTime", params.MINSMS * 60);
    commit("setVigenciaHashTime", params.TMPHASH);
    commit("setInactivityTime", params.TIEMPO_INACTIVIDAD_FRONT);
    commit("setMensajeInicioBanner", params.MENSAJE_INICIO_BANNER);
    commit("setSpecialCharacters", params.CANT_CARACTERES_ESPECIALES_CLAVE);
    commit("setMinLengthPass", params.RANGO_CARACTERES_CLAVE.split("-")[0]);
    commit("setMaxLengthPass", params.RANGO_CARACTERES_CLAVE.split("-")[1]);
    commit("setListaTiposIdentificacion", JSON.parse(params.TIPOS_IDENTIFICACION));
    commit("setInfoContacto", {
      prefijoContactoWsp: params.PREFIJO_CONTACTO_WSP,
      numeroContactoWsp: params.NUMERO_CONTACTO_WSP,
      mensajeWsp: params.MENSAJE_WSP,
    });
    commit("setInfoContactoProgreser", {
      numeroUnicoColombia: params.NUMERO_UNICO,
      whatsappCartera: params.WHATSAPP_CARTERA,
    });
  },
};

export default { state, getters, mutations, actions };
