const ValidarLevPrendaWeb = () =>
  import(/* webpackChunkName: "ValidarLevPrendaWeb" */ "../views/ValidarLevPrendaWeb.vue");
const LevantamientoPrenda = () =>
  import(/* webpackChunkName: "LevantamientoPrenda" */ "../views/LevantamientoPrenda.vue");
const LevantamientoPrendaWeb = () =>
  import(/* webpackChunkName: "LevantamientoPrendaWeb" */ "../views/LevantamientoPrendaWeb.vue");

export const routesLevantamientoPrenda = [
  {
    path: "/levantamiento-prenda",
    name: "LevantamientoPrenda",
    component: LevantamientoPrenda,
  },
];

export const routesLevantamientoPrendaWeb = [
  {
    path: "/validar-lev-prenda-web",
    name: "ValidarLevPrendaWeb",
    component: ValidarLevPrendaWeb,
    meta: { authGuard: false },
  },
  {
    path: "/levantamiento-prenda-web",
    name: "LevantamientoPrendaWeb",
    component: LevantamientoPrendaWeb,
    meta: { authGuard: false },
  },
];
