import CryptoJS from "crypto-js";
import EnvironmentService from "@/app/shared/services/environment.service";

export default class SeguridadService {
  static encriptarDatos(data) {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.enc.Base64.parse(EnvironmentService.SV_KEY), {
      iv: CryptoJS.enc.Base64.parse(EnvironmentService.SV_IV),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
      blockSize: 128,
      keySize: 256,
    });

    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  }

  static desencriptarDatos(data, iv) {
    const decrypted = CryptoJS.AES.decrypt(data, CryptoJS.enc.Base64.parse(EnvironmentService.SV_KEY), {
      iv: CryptoJS.enc.Base64.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
      blockSize: 128,
      keySize: 256,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
