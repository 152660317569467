import axios from "axios";
import EnvironmentService from "@/app/shared/services/environment.service";

const http = axios.create({
  baseURL: EnvironmentService.baseUrl,
  timeout: EnvironmentService.timeOut,
  headers: { "X-Requested-With": "XMLHttpRequest" },
});

export default http;
