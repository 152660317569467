const Login = () => import(/* webpackChunkName: "Login" */ "../views/Login.vue");
const Logout = () => import(/* webpackChunkName: "Logout" */ "../views/Logout.vue");
const Registro = () => import(/* webpackChunkName: "Registro" */ "../views/Registro.vue");
const RecuperarClave = () => import(/* webpackChunkName: "RecuperarClave" */ "../views/RecuperarClave.vue");
const ActualizarDatosWeb = () => import(/* webpackChunkName: "ActualizarDatosWeb" */ "../views/ActualizarDatosWeb.vue");

export const routesAuth = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { authGuard: false },
  },
  {
    path: "/registro",
    name: "Registro",
    component: Registro,
    meta: { authGuard: false },
  },
  {
    path: "/actualizar-datos-web",
    name: "ActualizarDatosWeb",
    component: ActualizarDatosWeb,
    meta: { authGuard: false },
  },
  {
    path: "/recuperar-clave",
    name: "RecuperarClave",
    component: RecuperarClave,
    meta: { authGuard: false },
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: { authGuard: true },
  },
];
