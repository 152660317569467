export function urlsNoMostrarAlerta(url) {
  return (
    !url.startsWith("/public/Usuario/ExisteUsuario") &&
    !url.startsWith("/ConsultaCredito/estadocuenta") &&
    !url.startsWith("/public/Token")
  );
}

export function urlsNoAuditoria(url) {
  return (
    url.startsWith("/public/Token") ||
    url.startsWith("/auditoria/ObtenerAcciones") ||
    url.startsWith("/public/auth/ParametrosIniciales")
  );
}

export function urlsNoMostrarLoading(url) {
  return url.startsWith("/auditoria") || url.startsWith("/public/Token");
}

export function urlsNoEncoding(url) {
  return url.startsWith("/tarjetarecaudo");
}

export function urlsNoValidToken(url) {
  return url.startsWith("/comunes/Autenticacion/Logout");
}
