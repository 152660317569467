const Dashboard = () => import(/* webpackChunkName: "Dashboard" */ "@/app/core/layouts/Dashboard.vue");

// ROUTES MODULES
import { routesAuth } from "../modules/auth/router";
import { routesHome } from "../modules/home/router";
import { routesPerfil } from "../modules/perfil/router";
import { routesPagos, routesPagosWeb } from "../modules/pagos/router";
import { routesContactarAsesor } from "../modules/contactar-asesor/router";
import { routesExtractosCertificados } from "../modules/extractos-y-certificados/router";
import { routesLevantamientoPrenda, routesLevantamientoPrendaWeb } from "../modules/levantamiento-prenda/router";

export const routes = [
  ...routesAuth,
  ...routesPagosWeb,
  ...routesLevantamientoPrendaWeb,
  {
    path: "/",
    name: "Menu",
    component: Dashboard,
    meta: { authGuard: true },
    children: [
      ...routesHome,
      ...routesPagos,
      ...routesPerfil,
      ...routesContactarAsesor,
      ...routesLevantamientoPrenda,
      ...routesExtractosCertificados,

      {
        path: "*",
        redirect: "/home",
      },
    ],
  },
  {
    path: "*",
    redirect: "/login",
  },
];
