import Vue from "vue";
import store from "@/app/core/store";
import { routes } from "./routes";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach((to, _from, next) => {
  if (to.matched.some((route) => route.meta.authGuard)) {
    if (!store.getters.isLogin) next("/login");
    else if (to.fullPath == "/") next("/home");
    else next();
  } else if (to.matched.some((route) => !route.meta.authGuard)) {
    if (store.getters.isLogin) next("/home");
    else if (to.fullPath == "/") next("/login");
    else next();
  } else {
    next();
  }
});

export default router;
