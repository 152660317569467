import axios from "axios";
import http from "@/app/core/config/axios.config";
import EnvironmentService from "@/app/shared/services/environment.service";

export default class PublicService {
  /* AUTENTICACION ------------------------------------------------------------------------------------------------------------ */

  static getIp() {
    return fetch("https://api.ipify.org?format=json&callback=getIP").then((response) => response.json());
  }

  static publicToken() {
    return http.get("/public/Token");
  }

  static login(entry) {
    return http.post("/public/auth/login", entry);
  }

  static getParametrosIniciales() {
    return http.get("/public/auth/ParametrosIniciales");
  }

  static cambiarClave(entry) {
    return http.post(`/public/Autenticacion/${entry.recuperarClave ? "RecuperarClave" : "CambiarClave"}`, entry);
  }

  static validarToken(entry) {
    return axios.post(`${EnvironmentService.baseUrl}/public/Token/Valid`, entry);
  }

  static getParametrosModalInicial() {
    return http.get("/comunes/MensajeInicio/MensajeModalInicial");
  }

  /* GENERACION CODIGOS ------------------------------------------------------------------------------------------------------- */

  static generarCodigoOtp(entry) {
    return http.post("/public/GeneracionCodigos/GenerarCodigoDobleAutenticacion", entry);
  }

  static validarCodigoOtp(entry) {
    return http.post("/public/GeneracionCodigos/ValidarCodigoDobleAutenticacion", entry);
  }

  static generarCodigoActualizarDatos(entry) {
    return http.post("/public/GeneracionCodigos/GenerarCodigoActualizarDatos", entry);
  }

  static validarCodigoActualizarDatos(entry) {
    return http.post("/public/ActualizacionInfo/ValidarCodigo", entry);
  }

  static reenviarCodigoActualizarDatos(entry) {
    return http.post("/public/ActualizacionInfo/GenerarReenvioCodigo", entry);
  }

  static generarCodigoClave(entry) {
    return http.post("/public/GeneracionCodigos/GenerarCodigoRecuperarClave", entry);
  }

  static generarCodigoRegistro(entry) {
    return http.post("/public/GeneracionCodigos/GenerarCodigoRegistroUsuario", entry);
  }

  static validarCodigoRegistro(entry) {
    return http.post("/public/GeneracionCodigos/ValidarCodigo", entry);
  }

  static validarHash(entry) {
    return http.post("/public/GeneracionCodigos/ValidarHash", entry);
  }

  /* USUARIO ------------------------------------------------------------------------------------------------------------------ */

  static validarUsuario(entry) {
    return http.get(`/public/Usuario/ExisteUsuario?NumCedula=${entry}`);
  }

  static crearUsuario(entry) {
    return http.post("/public/Usuario/CrearUsuario", entry);
  }

  static actualizarDatosUsuario(entry) {
    return http.post("/public/Usuario/ActualizarDatosUsuario", entry);
  }
}
