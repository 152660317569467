const Extractos = () => import(/* webpackChunkName: "Extractos" */ "../views/Extractos.vue");
const Certificados = () => import(/* webpackChunkName: "Certificados" */ "../views/Certificados.vue");

export const routesExtractosCertificados = [
  {
    path: "/generar-extractos",
    name: "Extractos",
    component: Extractos,
  },
  {
    path: "/generar-certificados",
    name: "Certificados",
    component: Certificados,
  },
];
