import { aplication } from "@/main";

const configToast = { autoHideDelay: 15000, appendToast: false, solid: true };

export default class AlertsService {
  static success(tittle, message) {
    aplication.$bvToast.toast(message, { title: tittle, variant: "success", ...configToast });
  }

  static info(tittle, message) {
    aplication.$bvToast.toast(message, { title: tittle, variant: "info", ...configToast });
  }

  static warning(tittle, message) {
    aplication.$bvToast.toast(message, { title: tittle, variant: "warning", ...configToast });
  }
  static error(tittle, message) {
    aplication.$bvToast.toast(message, { title: tittle, variant: "danger", ...configToast });
  }
}
