const state = {
  creditos: [],
  creditosActivos: [],
  isGetCreditos: false,
  parametrosPago: null,
  creditoSelected: null,
  creditosLevPrenda: [],
  isGetParametrosPago: false,
  isGetcreditosLevPrenda: false,
};

const getters = {
  creditos: (stateApp) => stateApp.creditos,
  isGetCreditos: (stateApp) => stateApp.isGetCreditos,
  parametrosPago: (stateApp) => stateApp.parametrosPago,
  creditosActivos: (stateApp) => stateApp.creditosActivos,
  creditoSelected: (stateApp) => stateApp.creditoSelected,
  creditosLevPrenda: (stateApp) => stateApp.creditosLevPrenda,
  isGetParametrosPago: (stateApp) => stateApp.isGetParametrosPago,
  isGetcreditosLevPrenda: (stateApp) => stateApp.isGetcreditosLevPrenda,
};

const mutations = {
  setinfoCreditos: (stateApp) => {
    stateApp.creditos = [];
    stateApp.creditosActivos = [];
    stateApp.isGetCreditos = false;
    stateApp.parametrosPago = null;
    stateApp.creditoSelected = null;
    stateApp.creditosLevPrenda = [];
    stateApp.isGetParametrosPago = false;
    stateApp.isGetcreditosLevPrenda = false;
  },
  setCreditos: (stateApp, data) => {
    stateApp.creditos = data;
  },
  setIsGetCreditos: (stateApp, data) => {
    stateApp.isGetCreditos = data;
  },
  setParametrosPago: (stateApp, data) => {
    stateApp.parametrosPago = data;
  },
  setCreditosActivos: (stateApp, data) => {
    stateApp.creditosActivos = data;
  },
  setCreditoSelected: (stateApp, data) => {
    stateApp.creditoSelected = data;
  },
  setCreditosLevPrenda: (stateApp, data) => {
    stateApp.creditosLevPrenda = data;
  },
  setIsGetParametrosPago: (stateApp, data) => {
    stateApp.isGetParametrosPago = data;
  },
  setIsGetCreditosLevPrenda: (stateApp, data) => {
    stateApp.isGetcreditosLevPrenda = data;
  },
};

const actions = {};

export default { state, getters, mutations, actions };
