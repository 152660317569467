<template>
  <b-modal centered hide-footer hide-header v-model="modal.isOpen" @close="closeModal()" @hidden="closeModal()">
    <b-row class="p-4">
      <b-col cols="12">
        <h5 class="primary text-center">{{ modal.titulo }}</h5>
        <div class="p-3 content-center">
          <i :class="`las fs-icon-confirms ${tipoConfirm}`"></i>
        </div>
        <p class="primary text-center py-2">{{ modal.mensaje }}</p>
      </b-col>
      <b-col cols="12" class="content-center">
        <b-button variant="danger" @click="closeModal()">Aceptar</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  computed: {
    modal() {
      return this.$store.getters.modalConfirmaciones;
    },
    tipoConfirm() {
      if (this.modal.tipo === "info") return "la-clock primary";
      if (this.modal.tipo === "danger") return "la-times-circle danger";
      if (this.modal.tipo === "success") return "la-check-circle success";
      return "";
    },
  },

  methods: {
    closeModal() {
      this.$store.commit("closeModalConfirms");
    },
  },
};
</script>
