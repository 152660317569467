const CambiarClave = () => import(/* webpackChunkName: "CambiarClave" */ "../views/CambiarClave.vue");
const ActualizarDatos = () => import(/* webpackChunkName: "ActualizarDatos" */ "../views/ActualizarDatos.vue");

export const routesPerfil = [
  {
    path: "/cambiar-clave",
    name: "CambiarClave",
    component: CambiarClave,
  },
  {
    path: "/actualizar-datos",
    name: "ActualizarDatos",
    component: ActualizarDatos,
  },
];
